import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts} from 'data/ui-texts';
import Background from 'components/ui/background/background';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './login.scss';

const LoginFacilitator = (props) => {
	const {
		isLoading,
		inputGameId,
		feedback,
		handleInput,
		handleLogin
	} = props;

	return (
		<div className="Login">
			<div className='Login-cphGamelabLogo' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
			<div className='Login-unordLogo'/>
			<Background mode='projector' />
			<div className="Login-logo">
				<Logo />
			</div>
			<div className="Login-box">
				<form className="Login-form" onSubmit={handleLogin}>
					<input
						name="inputGameId"
						type="text"
						placeholder={loginUiTexts.gameId} 
						autoComplete="section-facilitatorlogin game-id"
						value={inputGameId ? inputGameId : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<Button 
						isLoading={isLoading}
						classes={['login']}
						text={loginUiTexts.login} 
						onClick={handleLogin}
						type='submit'
					/>
					<p className="Login-errorMessage">{feedback}</p>
				</form>
			</div>
		</div>
	);
};

LoginFacilitator.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	inputGameId: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired	
};

export default LoginFacilitator;