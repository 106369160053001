import React from 'react';
import PropTypes from 'prop-types';
import {loginUiTexts, adminUiTexts} from 'data/ui-texts';
import Background from 'components/ui/background/background';
import Logo from 'components/ui/logo/logo';
import Button from 'components/ui/button/button';
import './admin-login.scss';

const AdminLogin = (props) => {
	const {
		isLoading,
		email,
		password,
		feedback,
		handleInput,
		handleLogin
	} = props;

	return (
		<div className="Login">
			<Background mode='projector' />
			<div className="Login-logo">
				<Logo />
			</div>
			<div className="Login-box">
				<form className="Login-form" onSubmit={handleLogin}>
					<p>{adminUiTexts.admin}</p>
					<input
						name="inputEmail"
						type="text"
						placeholder={loginUiTexts.email} 
						autoComplete="section-adminLogin email"
						value={email ? email : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<input
						name="inputPassword"
						type="password"
						placeholder={loginUiTexts.password}
						autoComplete="section-adminLogin password"
						value={password ? password : ''} 
						onChange={(event)=>{handleInput(event);}}
					/>
					<Button 
						isLoading={isLoading}
						classes={['login']}
						text={loginUiTexts.login} 
						onClick={handleLogin}
						type='submit'
					/>
					<p className="Login-errorMessage">{feedback}</p>
				</form>
			</div>
		</div>
	);
};

AdminLogin.propTypes = {
	isLoading: PropTypes.bool.isRequired,
	email: PropTypes.string,
	password: PropTypes.string,
	feedback: PropTypes.string,
	handleLogin: PropTypes.func.isRequired,
	handleInput: PropTypes.func.isRequired	
};

export default AdminLogin;