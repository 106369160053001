import React, { useEffect, useRef, useState } from 'react';
import './building.scss';
import PropTypes from 'prop-types';
import {ReactComponent as Building0} from '../../../assets/images/buildings/building0.svg';
import {ReactComponent as Building1} from '../../../assets/images/buildings/building1.svg';
import {ReactComponent as Building2} from '../../../assets/images/buildings/building2.svg';
import {ReactComponent as Building3} from '../../../assets/images/buildings/building3.svg';
import {ReactComponent as Building4} from '../../../assets/images/buildings/building4.svg';
import {ReactComponent as Building5} from '../../../assets/images/buildings/building5.svg';
import {ReactComponent as Building6} from '../../../assets/images/buildings/building6.svg';
import {ReactComponent as Building7} from '../../../assets/images/buildings/building7.svg';
import resetAnimation from 'helpers/animation-helper';
import AudioPlayer from 'react-h5-audio-player';
import { soundsData } from 'data/sound-data';

const Building = ({rank, player, isMuted}) => {
	const [currentRank, setCurrentRank] = useState(rank);
	const [previousRank, setPreviousRank] = useState(null);
	// State variables for svg animation handling
	const [playSignAnimation, setPlaySignAnimation] = useState(false);
	const [animationTimeout, setAnimationTimeout] = useState(null);
	
	let audioPlayerRef = useRef();

	useEffect(() => {
		const newCurrentRank = rank;
		const newPreviousRank = currentRank;
		setPreviousRank(newPreviousRank);
		setCurrentRank(newCurrentRank);

		resetAnimation('dustCloud' + player.id);
		resetAnimation('previousBuilding' + player.id);
		resetAnimation('currentBuilding' + player.id);

		if (!isMuted && rank > 0) {
			audioPlayerRef.current.audio.current.play();
		}
		setPlaySignAnimation(false);

		// Start delay for animation reset
		const timeout = setTimeout(() => {
			setPlaySignAnimation(true);
		}, 100);

		setAnimationTimeout(timeout);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [rank]);

	useEffect(() => {
		if (!isMuted && audioPlayerRef) {
			audioPlayerRef.current.audio.current.pause();
		}
		
		// Clear timeouts if any on unmount
		return () => {
			if (animationTimeout) {
				clearTimeout(animationTimeout);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const getBuildingComponent = (rank) => {
		switch (rank) {
		case 0: return Building0;
		case 1: return Building1;
		case 2: return Building2;
		case 3: return Building3;
		case 4: return Building4;
		case 5: return Building5;
		case 6: return Building6;
		case 7: return Building7;
		default: return Building0;
		}
	};

	const CurrentBuilding = getBuildingComponent(currentRank);
	const PreviousBuilding = getBuildingComponent(previousRank);

	return (
		<div className='Building'>
			{!isMuted &&
				<>
					<AudioPlayer
						ref={audioPlayerRef}
						muted={false}
						autoPlay={false}
						volume={0.75}
						src={soundsData.building}
						style={{display: 'none'}}
					/>
				</>
			}
			{previousRank !== null &&
				<div id={'previousBuilding' + player.id}
					className={'Building-rankBuilding previous rank-' + previousRank}>
					<PreviousBuilding className='Building-buildingSvg'/>
					<div className='Building-playerName'>
						<span>{player.name}</span>
					</div>
				</div>
			}
			<div id={'currentBuilding' + player.id}
				className={'Building-rankBuilding current rank-' + currentRank}>
				<CurrentBuilding className={'Building-buildingSvg' + (playSignAnimation ? ' playAnimation' : '')}/>
				<div className='Building-playerName'>
					<span>{player.name}</span>
				</div>
			</div>
			<div id={'dustCloud' + player.id} className='Building-dustCloud'/>
		</div>
	);
};

Building.propTypes = {
	rank: PropTypes.number.isRequired,
	player: PropTypes.object.isRequired,
	isMuted: PropTypes.bool.isRequired,
};

export default Building;