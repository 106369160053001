const pageUiTexts = { // pageTexts
	gameCode: 'Spilkode:',
	startTimer: 'Start timer',
	groupReady: 'klar',
	groupNotReady: 'ikke klar',
	cancel: 'fortryd',
	attention: 'Følg med på tavlen',
	url: 'URL:',
	bestOfTheDay: 'dagens bedste',
	reset: 'reset',
};

const errorUiTexts = {
	missingFields: 'Fejl: Husk at udfylde alle felter.',
	unknownError: 'Fejl: Ukendt fejl.',
	emailNotFound: 'Fejl: Der findes ingen bruger med denne email.',
	invalidEmail: 'Fejl: Din email er ikke gyldig.',
	invalidEmailPassword: 'Fejl: Check om din e-mail og password er korrekte.',
	noPasswordMatch: 'Password skal være ens i begge felter.',
	weakPassword: 'Dit password skal være på mindst 8 tegn.',
	noGameCodeMatch: 'Fejl: Ugyldig spilkode.',
	groupNameTooLong: 'Fejl: Gruppenavn må ikke være længere end 15 tegn.',
	groupNameTooShort: 'Fejl: Gruppenavn skal være på mindst 2 tegn.',
	duplicatePlayerEmail: 'Fejl: Du har allerede tilføjet en spiller med denne e-mail.',
	userDataNotFound: 'Error: User data not in database. Auto log out in 5 seconds.',
	couldNotGetUser: 'Error: %error%. Auto log out in 5 seconds.',
	unknownPlayer: 'Error: Unknown player id!',
};

const loginUiTexts = {
	login: 'Log ind',
	gameId: 'login',
	email: 'Email',
	password: 'Kodeord',
	passwordRepeat: 'gentag password',
	countDown1: 'Spil starter om ',
	countDown2: ' sek...',
	waitingFor1: 'Venter på spiller ',
	waitingFor2: '...',
	insertName: 'Indtast Navn:',
	start: 'start',
	gameGuide: 'Svar rigtigt på så mange spørgsmål som muligt. Flere rigtige svar i træk giver flere point per spørgsmål.'
};

const generalUiTexts = {
	lobby: 'Lobby',
	logout: 'Log ud',
	loading: 'Loading',
	popupTitle: 'Advarsel!',
	cancel: 'cancel',
	continue: 'Fortsæt',
	title: 'Lobby',
	player: 'Spiller',
	gameStartsIn: 'Spil start om:'
};

const gameUiTexts = {
	choose: 'vælg',
	resetGame: 'Tilbage til start',
	gameReturnsIn1: 'Spillet nulstiller om ',
	gameReturnsIn2: ' sek...',
	airplaneBanner: [
		'Tør du udfordre din ven i faglig viden?',
		'Er I klar på en duel?'
	]
};

const settingsUiTexts = {
	instruction: 'Hvilken enhed logger ind?',
	choose: 'Vælg',
	tablet: 'Tablet',
	projektor: 'Projektor',
};

const popupsData = {
	won: 'vandt!',
	didNotWin: 'blev nr. 2!'
};

const assignmentsUiTexts = {
	point: 'Point',
	time: 'Tid',
	rank: 'Rank',
	steak: 'Svar streak',
	noMore: 'Du har svaret på alle spørgsmål!'
};

const adminUiTexts = {
	admin: 'Admin',
	gameIdLabel: 'spil-id',
	createGame: 'Opret nyt spil',
	games: 'Eksisterende spil',
	gameId: 'Spil-id',
	gameIdAlreadyExists: 'Der eksisterer allerede et spil med dette id.',
	couldNotCreateGame: 'Kunne ikke oprette spil.'
};

module.exports = {
	settingsUiTexts,
	generalUiTexts,
	loginUiTexts,
	errorUiTexts,
	pageUiTexts,
	popupsData,
	assignmentsUiTexts,
	gameUiTexts,
	adminUiTexts
};
