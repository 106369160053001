/**
 * Gets time left in seconds until the given time is reached
 * @param {Date} endTime in seconds
 */
function getTimeUntil(endTime) {
	const currentTime = new Date().getTime();

	const timeLeft = (endTime < currentTime
		? 0
		: Math.round((endTime - currentTime) / 1000.)
	);

	return timeLeft;
};
/**
 * Returns true if the data is today otherwise false
 * @param {Date} date in toDateString() format
 */
function isToday(date) {
	return new Date().toDateString() === date ? true : false;
}

export {
	getTimeUntil,
	isToday
};