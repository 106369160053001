import React from 'react';
import PropTypes from 'prop-types';
import Background from 'components/ui/background/background';
import './game.scss';

const Game = ({mode, pageId, children}) => {

	return (
		<div className={'Game ' + mode}>
			<Background mode={mode} pageId={pageId} />
			{children}
		</div>
	);
};

Game.propTypes = {
	mode: PropTypes.string.isRequired,
	pageId: PropTypes.string,
	children: PropTypes.array.isRequired
};

export default Game;