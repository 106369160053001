import React from 'react';
import PropTypes from 'prop-types';
import { generalUiTexts, settingsUiTexts } from 'data/ui-texts';
import Background from 'components/ui/background/background';
import Button from 'components/ui/button/button';
import Logo from 'components/ui/logo/logo';
import './settings.scss';

const Settings = ({game, players, choosePlayer, chooseProjector, handleLogout}) => {
	const handleChoosePlayer = (playerId) => {
		choosePlayer(playerId);
	};
	
	return (
		<div className="Settings">
			<div className='Settings-cphGamelabLogo' onClick={() => {window.open('https://cphgamelab.dk/', '_blank', 'noopener,noreferrer');}}/>
			<div className='Settings-unordLogo'/>
			<Background mode='projector' />
			<div className="Settings-logo">
				<Logo />
			</div>

			<div className='Settings-titleWrapper'>
				<div className='Settings-title'><span>{settingsUiTexts.instruction}</span></div>
			</div>
			
			<div className='Settings-dialogueWrapper'>
				<div className='Settings-choosePlayerType'>
					<div className='Settings-chooseProjector'>
						
						<div className='Settings-choosePlayerTypeTitle'>
							<span>{settingsUiTexts.projektor}</span>
						</div>
						<div className='Settings-buttonWrapper'>
							<Button
								isDisabled={!game}
								text={settingsUiTexts.choose}
								classes={['flatBlue']}
								onClick={() => {chooseProjector();}}
							/>
						</div>
					</div>

					{players.map((player, index) => {
						return (
							<div key={index} className={`Settings-choosePlayer player-${index + 1}`}>
								<div className='Settings-choosePlayerTypeTitle'>
									<span>{settingsUiTexts.tablet + ' ' + player.id}</span>
								</div>
								<div className='Settings-buttonWrapper'>
									<Button
										isDisabled={!game}
										text={settingsUiTexts.choose}
										classes={['flatBlue']}
										onClick={() => {handleChoosePlayer(player.id);}}
									/>
								</div>
							</div>
						);
					})}
					
				</div>
			</div>

			<div className='Settings-navigation'>
				<Button
					text={generalUiTexts.logout}
					classes={['flatBlue']}
					onClick={() => {handleLogout();}}
				/>
			</div>
		</div>
	);
};

Settings.propTypes = {
	game: PropTypes.object,
	players: PropTypes.array.isRequired,
	choosePlayer: PropTypes.func.isRequired,
	chooseProjector: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Settings;
