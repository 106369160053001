import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import apiHelper from 'helpers/api-helper';
import {errorUiTexts} from 'data/ui-texts';
import appConfig from 'config/app.config';
import Login from './login';
import FacilitatorController from 'components/users/facilitator-controller';
import './login.scss';

class LoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLoggedIn: false,
			gameId: null,
			inputGameId: null,
			feedback: null
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Check if logged in */
		this.checkIfLoggedIn();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user && user.uid) {
				/* Logged in */
				this.setState({
					isLoggedIn: true,
					isLoading: false,
					gameId: user.uid,
					inputGameId: null,
					feedback: null
				});
			} else {
				/* Not logged in */
				this.setState({
					isLoading: false,
					isLoggedIn: false,
					gameId: null,
					inputGameId: null,
					feedback: null
				});
			}
		});	
	};

	/**
	 * Logout
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoading: false,
			isLoggedIn: false,
			gameId: null,
			inputGameId: null,
			feedback: null
		});
	};

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	 handleInput = (event) => {
		const value = event.target.value;
		const name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	};

	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		if (event) event.preventDefault();
		this.setState({isLoading: true}, () => {
			/* Error: missing fields */
			if (!this.state.inputGameId || this.state.inputGameId.length < 1) {
				this.setState({
					isLoading: false,
					feedback: errorUiTexts.missingFields
				});
				return;
			}

			/* Check if game exists */
			const inputGameId = this.state.inputGameId.toLowerCase();
			this.setState({isLoading: true}, () => {
				const db = firebase.firestore();
				db.collection(appConfig.gamesDbName).doc(inputGameId).get().then((doc) => {
					if (doc.exists) {
						/* Game exists, get login token and login */
						this.handleGetLoginToken(inputGameId);
					} else {
						/* Game does not exist */
						this.setState({isLoading: false, feedback: errorUiTexts.noGameCodeMatch});
					}
				}).catch((error) => {
					console.error(error);
					this.setState({isLoading: false, feedback: errorUiTexts.unknownError});
				});	
			});
		});
	};

	/**
	 * Get a login token for the game and log in
	 * @param {number} inputGameId 
	 */
	handleGetLoginToken = (inputGameId) => {
		apiHelper('facilitator/get-login-token', {gameId: inputGameId}).then(
			(response)=>{
				if (response.status === 'success' && response.token) {
					firebase.auth().signInWithCustomToken(response.token).then(() => {
					// All okay, should trigger the authStateChanged in LoginController
					}).catch((error) => {
						console.error('sign in error', error);
						this.setState({
							isLoading: false,
							gameId: null,
							feedback: errorUiTexts.unknownError,
						});
					});
				} else {
					console.error(response);
					this.setState({
						isLoading: false,
						gameId: null,
						feedback: errorUiTexts.unknownError
					});
				}
			},
			(rejection) => {
				console.error('rejection: ', rejection);
				this.setState({
					isLoading: false,
					feedback: errorUiTexts.unknownError
				});
			}
		);
	};

	/**
 	* Render component
 	*/
	render = () => {	
		/* User is not loggged in */
		if (!this.state.isLoggedIn) {
			return (
				<Login 
					isLoading={this.state.isLoading}
					inputGameId={this.state.inputGameId}
					feedback={this.state.feedback}
					handleInput={this.handleInput}
					handleLogin={this.handleLogin}
				/>
			);
		}

		/* User is logged in */
		return (
			<FacilitatorController 
				gameId={this.state.gameId}
				handleLogout={this.handleLogout}
			/>
		);
	};
}

export default LoginController;