import React from 'react';
import PropTypes from 'prop-types';
import './background.scss';

const Background = ({mode, pageId}) => {

	return (
		<div className={'Background ' + mode + (pageId ? ' ' + pageId : '')}> 
			<div className='Background-clouds'/>
			<div className='Background-skyline'/>
			<div className='Background-buildings'/>
			<div className='Background-car'/>
		</div>
			
	);
};
Background.propTypes = {
	mode: PropTypes.string.isRequired,
	pageId: PropTypes.string
};
export default Background;