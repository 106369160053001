const ranksData = [
	{
		name: 'Kongen',
		// Threshold percentage
		threshold: 75,
		id: 7,
	},
	{
		name: 'Champ',
		threshold: 60,
		id: 6,
	},
	{
		name: 'Mester',
		threshold: 37,
		id: 5,
	}, 
	{
		name: 'Svend',
		threshold: 25,
		id: 4,
	}, 
	{
		name: 'Lærling',
		threshold: 16,
		id: 3,
	}, 
	{
		name: 'Glad Amatør',
		threshold: 8,
		id: 2,
	},
	{
		name: '10 Tommelfingre',
		threshold: 3,
		id: 1,
	},
];

module.exports = {ranksData};