import React, {Component} from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import {errorUiTexts} from 'data/ui-texts';
import Login from './admin-login';
import Admin from './admin';

class AdminLoginController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			isLoading: true,
			isLoggedIn: false,
			userId: null,
			email: null,
			inputEmail: null,
			inputPassword: null,
			feedback: null
		};
		this.unsubscribeOnAuthStateChanged = null;
	}

	/**
	 * Component did mount
	 */
	componentDidMount = () => {
		/* Check if logged in */
		this.checkIfLoggedIn();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		if (this.unsubscribeOnAuthStateChanged !== null) this.unsubscribeOnAuthStateChanged();
	};

	/**
	 * Check if user is logged in
	 */
	checkIfLoggedIn = () => {
		// Unsubscribe previous onAuthStateChanged
		if (this.unsubscribeOnAuthStateChanged !== null) {
			this.unsubscribeOnAuthStateChanged();
		}

		// Subscribe to onAuthStateChanged
		this.unsubscribeOnAuthStateChanged = firebase.auth().onAuthStateChanged((user)=>{
			if (user && user.uid && user.email) {
				/* Logged in */
				this.setState({
					isLoggedIn: true,
					isLoading: false,
					userId: user.uid,
					email: user.email
				});
			} else {
				/* Not logged in */
				this.setState({
					isLoading: false,
					isLoggedIn: false,
					userId: null,
					email: null,
				});
			}
		});	
	};

	/**
	 * Logout
	 */
	handleLogout = () => {
		firebase.auth().signOut();
		this.setState({
			isLoading: false,
			isLoggedIn: false,
			userId: null,
			email: null,
		});
	};

	/**
	 * Update input field
	 * @param {obj} event 
	 */
	 handleInput = (event) => {
		const value = event.target.value;
		const name = event.target.name;
		this.setState({
			[name]: value,
			feedback: null
		});
	};

	/**
	 * Handle login
	 * @param {obj} event 
	 */
	handleLogin = (event) => {
		if (event) event.preventDefault();
		this.setState({isLoading: true}, () => {
		/* Error: missing fields */
			let email = this.state.inputEmail;
			let password = this.state.inputPassword;
			if (!email || !password) {
			
				this.setState({
					isLoading: false,
					feedback: errorUiTexts.missingFields
				});
				return;
			}
			/* Sign in */
			firebase.auth().signInWithEmailAndPassword(email, password)
				.catch((error) => {
					let errorMsg = errorUiTexts.unknownError;
					if (error.code === 'auth/invalid-email') errorMsg = errorUiTexts.invalidEmail;
					if (error.code === 'auth/user-not-found') errorMsg = errorUiTexts.emailNotFound;
					if (error.code === 'auth/wrong-password') errorMsg = errorUiTexts.invalidEmailPassword;
					this.setState({feedback: errorMsg, isLoading: false});
				});
		});
	};


	/**
 	* Render component
 	*/
	render = () => {	
		/* User is not loggged in */
		if (!this.state.isLoggedIn) {
			return (
				<Login 
					isLoading={this.state.isLoading}
					email={this.state.inputEmail}
					password={this.state.inputPassword}
					feedback={this.state.feedback}
					handleInput={this.handleInput}
					handleLogin={this.handleLogin}
				/>
			);
		}

		/* User is logged in */
		return (
			<Admin 
				userId={this.state.userId}
				handleLogout={this.handleLogout}
			/>
		);
	};
}

export default AdminLoginController;