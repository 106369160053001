import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { assignmentsUiTexts } from 'data/ui-texts';
import { ranksData } from 'data/rank-data';
import Logo from 'components/ui/logo/logo';
import Timer from 'components/ui/timer/timer';
import Points from 'components/ui/points/points';
import './assignments.scss';
import MultipleChoice from 'components/game/module/multiple-choice/multiple-choice';
import { tasks } from 'data/module-tasks/tasks';
import { calculateNewScore, calculateStreak } from 'helpers/score-helper';
import { calculateRank } from 'helpers/rank-helper';
import resetAnimation from 'helpers/animation-helper';
import { shuffleArray } from 'helpers/array-helper';
import appConfig from 'config/app.config';

const Assignments = ({ player, updatePlayer, timeLeft }) => {
	const [isAnimatingRank, setIsAnimatingRank] = useState(false);
	const [hasNoMoreAssignments, setHasNoMoreAssignments] = useState(false);
	const [currentRankIndex, setCurrentRankIndex] = useState(calculateRank(player.score));

	// Timeout before going to next task
	let timeout = null;
	
	// Current task engine (currently only multiple choice)
	const CurrentTaskEngine = MultipleChoice;

	// Current task
	const [currentTask, setCurrentTask] = useState(null);
	const [currentTaskIndex, setCurrentTaskIndex] = useState(0);
	const [randomizedTaskList, setRandomizedTaskList] = useState(null);

	// Set current points to points on mount
	useEffect(() => {
		const randomizedTasks = shuffleArray(tasks);
		setCurrentTask(randomizedTasks[0]);
		setRandomizedTaskList(randomizedTasks);

		// Clear intervals if any on unmount
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	// Keeps track of player current streak
	const [currentStreak, setCurrentStreak] = useState(0);

	// On current streak change, replay animation for streakMarker
	useEffect(() => {
		resetAnimation('streakMarker');
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStreak]);

	/**
	 * Handles completion of task
	 * @param {bool} isCorrect 
	 */
	const handleCompleteTask = (isCorrect) => {
		let newCurrentStreak = currentStreak;
		let newPoints = player.points;
		if (isCorrect) {
			// Increase streak
			newCurrentStreak = calculateStreak(currentStreak);
			// Give points
			newPoints = calculateNewScore(player.points, newCurrentStreak);

			// Update rank with new points
			// check if rank has changed if yes then retrigger crown animation
			const newRank = calculateRank(newPoints);
			if (currentRankIndex !== newRank) {
				setIsAnimatingRank(true);
				resetAnimation('crownIcon');
			}
			setCurrentRankIndex(newRank);
		} else {
			// Reset streak
			newCurrentStreak = 0;
		}
		// Save new streak
		setCurrentStreak(newCurrentStreak);
		updatePlayer({points: newPoints, streak: newCurrentStreak > 1 ? newCurrentStreak : 0});
		
		const newCurrentTaskIndex = currentTaskIndex + 1;
		setCurrentTaskIndex(newCurrentTaskIndex);
		// Start delay of 1 second
		timeout = setTimeout(() => {
			// Set currentTask to next task, if next task exists
			if ((randomizedTaskList.length - 1) >= newCurrentTaskIndex) {
				setCurrentTask(randomizedTaskList[newCurrentTaskIndex]);
			} else {
				setHasNoMoreAssignments(true);
			}
		}, 1000);
	};

	return (
		<div className='Assignments'>
			{/* Left side */}
			<div className='Assignments-sideWrapper left'>
				<div className='Assignments-logo'><Logo /></div>

				<div className={'Assignments-displayNameWrapper ' + 
				(player ? `Assignments-displayNameWrapper-${player.id}` : '')}>
					<div className={'Assignments-displayName'}>
						<span>{player.name}</span>
					</div>
				</div>

				<div className='Assignments-points'>
					<Points 
						name={assignmentsUiTexts.point} 
						points={player.points}
						type={'trophy'}
						currentStreak={currentStreak > 1 ? currentStreak : null}
						hasStreakIndicator={true}
					/>
				</div>

				<div className='Assignments-timer'>
					{timeLeft !== null && 
						<Timer 
							totalSeconds={timeLeft}
						/>
					}
				</div>
			</div>

			{/* Task */}
			<div className='Assignments-taskWindow'>
				{hasNoMoreAssignments ?
					<div className='Assignments-noMoreTaskPopup'>
						<span>
							{assignmentsUiTexts.noMore}
						</span>
					</div>
					:
					<div className='Assignments-task'>
						{currentTask &&
							<CurrentTaskEngine 
								taskData={currentTask}
								handleCompleteTask={handleCompleteTask}
							/>
						}

						{(currentTask && currentStreak > 1) && 
							<div id='streakMarker' className={'Assignment-streakMarker streak' 
								+ (currentStreak > appConfig.maxStreak ? appConfig.maxStreak : currentStreak)}/>
						}
					</div>
				}
			</div>

			{/* Right side */}
			<div className='Assignments-sideWrapper right'>
				<div className={'Assignments-rankWrapper' + (isAnimatingRank ? ' pulseScale' : '')}>
					<div className='Assignments-rank'>
						<span>{assignmentsUiTexts.rank}</span>
						<div className='Assignments-rankProgressbarWrapper'>
							{ranksData.map((item, index) => {
								let completionIndicatorClass = '';
								if (item.id === currentRankIndex) {
									completionIndicatorClass = 'current';
									if (isAnimatingRank) {
										completionIndicatorClass += ' pulseScale';
									} 
								} else if (item.id < currentRankIndex) {
									completionIndicatorClass = 'complete';
								}

								return (
									<div className='Assignments-rankProgressbar' key={index}>
										<div className='Assignments-emptyBar'/>
										<div>
											<span>{item.name}</span>
										</div>
										<div className={'Assignments-completionIndicator ' + completionIndicatorClass}/>
									</div>
								);
							})}
						</div>
						<div id='crownIcon' 
							className={'Assignments-crownIcon ' + (isAnimatingRank ? ' pulseScale' : '')}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};
Assignments.propTypes = {
	player: PropTypes.object.isRequired,
	updatePlayer: PropTypes.func.isRequired,
	timeLeft: PropTypes.number,
};

export default Assignments;