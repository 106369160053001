/* eslint-disable camelcase */
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';

/* Log environment */
let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
console.log('ENVIRONMENT: ', env);

let firebaseConfig = {};

/* Connect to firebase project test (includes demo) */
if (env === 'development' || env === 'test' || env === 'demo') {
	firebaseConfig = {
		apiKey: 'AIzaSyC3rjEOoB7pPyBFqmBVxQJXF6sQvbLC8VA',
		authDomain: 'cgl-messespil-test.firebaseapp.com',
		projectId: 'cgl-messespil-test',
		storageBucket: 'cgl-messespil-test.appspot.com',
		messagingSenderId: '1068237566872',
		appId: '1:1068237566872:web:7e56e6a80af57b42c771ed'
	};
}

/* Connect to firebase: production */
if (env === 'production') {
	firebaseConfig = {
		apiKey: 'AIzaSyDHYotdH23D734B4IjUpASh882NiD8SqKU',
		authDomain: 'cgl-messespil-production.firebaseapp.com',
		projectId: 'cgl-messespil-production',
		storageBucket: 'cgl-messespil-production.appspot.com',
		messagingSenderId: '109765621749',
		appId: '1:109765621749:web:27db880ec6b5df730ad2bc'
	};
}

firebase.initializeApp(firebaseConfig);

export default firebase.firestore;