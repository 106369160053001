import React from 'react';
import PropTypes from 'prop-types';
import './timer.scss';
import { assignmentsUiTexts } from 'data/ui-texts';
import appConfig from 'config/app.config';

const Timer = ({ totalSeconds }) => {
	const initialTime = appConfig.gameDurationSeconds;

	const minutes = Math.floor(totalSeconds / 60);
	const seconds = totalSeconds % 60;
	// Calculates the percentage of fill for the circle
	const percentageComplete = (totalSeconds / initialTime) * 100;
	
	return (
		<div className={'Timer ' + (percentageComplete <= 25 ? 'pulseScale' : '')}>
			<div className='Timer-window'>
				<span>{assignmentsUiTexts.time}</span>
				<div className='Timer-chart'>
					{/* Background circle, contains color for countdown circle */}
					<div className={'Timer-backgroundCircle ' + (percentageComplete <= 25 ? 'pulseScale' : '')}/>
					{/* Conic gradient, with hard edges, using percentage from watch to count down
						Has transparent color to reveal background circle beneath */}
					<div className={'Timer-circle'}
						style={{
							background: 'conic-gradient(#ffffff00 ' + percentageComplete + '%, #BEE0EA ' + 1 + '%)'
						}}/>
					{/* Center circle cutout */}
					<div className='Timer-centerCircle'/>
					{/* timer in the center */}
					<div className='Timer-clock'>
						{totalSeconds === 0
							? <span > 
								{'0:00'}
							</span> 
							: <span > 
								{minutes}:{seconds < 10 ?  `0${seconds}` : seconds}
							</span> 
						}
					</div>
				</div>
			</div>
		</div>
	);
};

Timer.propTypes = {
	totalSeconds: PropTypes.number.isRequired,
};

export default Timer;