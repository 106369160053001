import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {gamePagesData} from 'data/game-pages-data';
import {getTimeUntil} from 'helpers/time-helper';
import Loading from 'components/loading/loading';
import Game from './game';

// Player pages
import Lobby 		from 'components/game-steps/player/lobby/lobby';
import Assignments 	from 'components/game-steps/player/assignments/assignments';

// Projector Pages
import PauseScreen 	from 'components/game-steps/projector/pause-screen/pause-screen';
import Buildings 	from 'components/game-steps/projector/buildings/buildings';
import appConfig from 'config/app.config';
import GameOver from 'components/ui/game-over/game-over';
import { sortArrayByProperty } from 'helpers/array-helper';
import ConfettiCannon from 'components/ui/confetti-cannon/confetti-cannon';
import GameOverProjector from 'components/ui/game-over-projector/game-over-projector';

const gamePages = {
	// player
	lobby:			{component: Lobby},
	assignment: 	{component: Assignments},

	// Projector
	pauseScreen: 	{component: PauseScreen},
	buildings: 		{component: Buildings}
};

class GameController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			timeLeft: null,
			showGameOver: false,
			isMuted: false,
		};
		this.interval = null;
	}

	/**
	 * Component did mount 
	 */
	componentDidMount = () => {
		if (this.props.game.startTime) {
			/* Game initialized, start countdown */
			this.startCountDown();
		}

		this.handleGameState();
	};

	/**
	 * Component did update
	 */
	componentDidUpdate = () => {
		this.handleGameState();
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear interval */
		if (this.interval) clearInterval(this.interval);
	};

	handleGameState = () => {
		if (this.props.game && this.props.players.length > 0) {
			const currentTime = new Date().getTime(); // miliseconds
			
			/* Check if game should be initialized */
			if (!this.props.isPlayer && !this.props.game.startTime && !this.state.showGameOver) {
				if (this.props.players[0].isReady && this.props.players[1].isReady) {
					/* Both players ready: initialize game */
					const startTime = currentTime + (appConfig.gameCountdownSeconds * 1000);
					this.props.updateGame({startTime});
				}
			} else if (!this.props.game.startTime && this.state.showGameOver) {
				// If the game has no startTime but we are set to show game over
				// The game is assumed to have been manually reset
				this.setState({timeLeft: null, showGameOver: false});
				if (this.interval) clearInterval(this.interval);
				this.interval = null;
				return;
			}

			/* Check if a countdown should be started */
			if (!this.interval && this.props.game.startTime) {
				this.startCountDown();
			}
		
			/* Check if game should reset */
			if (
				this.props.game.startTime &&
				currentTime > this.props.game.startTime 
					+ (appConfig.gameDurationSeconds + appConfig.gameCooldownSeconds) * 1000
			) {
				/* Cooldown is over: reset game */
				if (this.interval) {
					clearInterval(this.interval);
					this.interval = null;
				}
				this.props.resetGame();
			}

			/* If we have timeleft, game over popup is set to be shown, but we have no startTime in game */
			/* We assume then that the game has been reset, and thus reset our state */
			/* This is necessary as the game may have been reset by another device */
			if (!this.props.game.startTime && this.state.timeLeft !== null &&
				(this.state.showGameOver || !this.props.players[0].name)) {
				if (this.interval) clearInterval(this.interval);
				this.setState({timeLeft: null, showGameOver: false});
			}
		}
	};

	/**
	 * Updates highscore on game database object, based on player scores
	 */
	updateHighScore = () => {
		let currentHighscore = this.props.game.highscoreList;
		// If there is room for more, we just add the players scores
		currentHighscore.push({
			name: this.props.players[0].name, 
			score: this.props.players[0].points
		});
		currentHighscore.push({
			name: this.props.players[1].name, 
			score: this.props.players[1].points
		});
		currentHighscore = sortArrayByProperty(currentHighscore, 'score', 'DESC');

		if (currentHighscore.length > 10) {
			currentHighscore = currentHighscore.slice(0, 10);
		}

		this.props.updateGame({highscoreList: currentHighscore});
	};


	/**
	 * Count down to game start / game end / game reset
	 */
	startCountDown = () => {
		if (this.interval) clearInterval(this.interval);

		const startTime = this.props.game.startTime;
		if (startTime) {
			const endTime = startTime + (appConfig.gameDurationSeconds * 1000);
			const resetTime = endTime + (appConfig.gameCooldownSeconds * 1000);
			const currentTime = new Date().getTime(); // miliseconds	

			let counterEndTime = null;
			if (currentTime >= startTime) {
				/* Countdown to game end */
				if (currentTime < endTime) {
					counterEndTime = endTime;
				} else {
					if (currentTime < resetTime) {
						/* Projector updates highscore list */
						if (!this.props.isPlayer && !this.state.showGameOver) {
							this.updateHighScore();
						}
						/* Countdown to game reset */
						/* Showing game over popup */
						this.setState({showGameOver: true});
						counterEndTime = resetTime;
					} else if (this.state.timeLeft) {
						/* No more countdowns, reset game */
						if (this.interval) {
							clearInterval(this.interval);
							this.interval = null;
						}
						this.props.resetGame();
					}
				}
			} else {
				/* Countdown to game start */
				counterEndTime = startTime;
			}

			/* Start countdown */
			if (counterEndTime) {
				this.interval = setInterval(() => {
					const timeLeft = getTimeUntil(counterEndTime);
					this.setState({timeLeft: timeLeft});
					if (timeLeft <= 0) {
						this.startCountDown();
					}
				}, 1000);
			}
		}
	};
	
	/**
	 * Get current page id
	 */
	getCurrentPageId = () => {
		let currentPageId = null;

		if (this.props.game) {
			let currentPage = 'lobby';
			const currentTime = new Date().getTime(); // miliseconds

			if (this.props.game.startTime && currentTime > this.props.game.startTime) {
				/* Game started */
				currentPage = 'assignment';
			}

			const currentPageData = gamePagesData[currentPage];
			currentPageId = (this.props.isPlayer
				? currentPageData.tabletPage
				: currentPageData.projectorPage
			);
		}
	
		return currentPageId;
	};

	/**
	 * Toggles mute on/off
	 */
	toggleMute = () => {
		this.setState({isMuted: !this.state.isMuted});
	};
	
	resetGame = () => {
		this.props.resetGame().then(() => {
			if (this.interval) {
				clearInterval(this.interval);
				this.interval = null;
			}
	
			this.setState({timeLeft: null, showGameOver: false});
		});
	};

	/**
	 * Render component
	 */
	render = () => {
		/* Get page id */
		const currentPageId = this.getCurrentPageId();

		/* Get component */
		const CurrentComponent = (currentPageId
			? gamePages[currentPageId].component
			: Loading
		);
		
		/* Get view mode (projector / tablet)  */
		const mode = (this.props.isPlayer ? 'tablet' : 'projector');

		const GameOverScreen = (this.props.isPlayer ? GameOver : GameOverProjector);

		return (
			<Game mode={mode} pageId={currentPageId}>
				{this.state.showGameOver &&
					<div>
						<GameOverScreen
							player={this.props.player}
							players={this.props.players}
							handleClose={() => {this.resetGame();}}
							timeLeft={this.state.timeLeft}
							isMuted={this.state.isMuted}
						/>
						{!this.props.isPlayer &&
							<ConfettiCannon 
								colors={['#EB1CB5', '#4DB4FC', '#60CB4D', '#F85454', '#E3A626', '#7D64A8']}
							/>
						}
					</div>
				}
				<CurrentComponent
					isShowingGameOver = {this.state.showGameOver}
					timeLeft = {this.state.showGameOver ? 0 : this.state.timeLeft}
					game = {this.props.game}
					player = {this.props.player}
					players = {this.props.players}
					updatePlayer = {this.props.updatePlayer}
					updateGame = {this.props.updateGame}					
					handleLogout={this.props.handleLogout}
					resetGame={this.resetGame}
					isMuted={this.state.isMuted}
					toggleMute={this.toggleMute}
				/>
			</Game>
		);
	};
}

GameController.propTypes = {
	isPlayer: PropTypes.bool.isRequired,
	handleLogout: PropTypes.func.isRequired,
	updateGame: PropTypes.func,
	resetGame:PropTypes.func,
	game: PropTypes.object,
	player: PropTypes.object,
	players: PropTypes.array,
	updatePlayer: PropTypes.func,
};

export default GameController;