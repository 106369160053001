import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import './points.scss';
import resetAnimation from 'helpers/animation-helper';
import appConfig from 'config/app.config';
import AudioPlayer from 'react-h5-audio-player';
import { soundsData } from 'data/sound-data';

const Points = ({type, name, points, currentStreak = null, hasStreakIndicator = true, isMuted = false}) => {
	const [currentPoints, setCurrentPoints] = useState(0);

	// Stops animation from running initially
	const [shouldTrophyAnimationRun, setShouldTrophyAnimationRun] = useState(false);

	// Keeps track of if this is first render or not
	const [isFirstRender, setIsFirstRender] = useState(true);
	
	let timeout = null;
	if (currentPoints < points) {
		let newCurrentPoints = currentPoints + 1;
		// End interval once we reach points
		timeout = setTimeout(() => {
			setCurrentPoints(newCurrentPoints);
		}, 20);
	}
	
	// On current point change, replay animation for trophyIndicator
	// Also starts animation initially
	useEffect(() => {
		if (!isFirstRender) {
			setShouldTrophyAnimationRun(true);
			resetAnimation('trophyIndicator-' + type);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [points]);

	let audioPlayerRef = useRef();

	// On current streak change, replay animation for streakindicator/streakMarker
	// Also starts animation initially
	const [streakTimeout, setStreakTimeout] = useState(null);
	const [streakSoundTimeout, setStreakSoundTimeout] = useState(null);
	const [oldStreak, setOldStreak] = useState(currentStreak);
	useEffect(() => {
		if (hasStreakIndicator) {
			resetAnimation('streakIndicator');
		} else {
			setOldStreak(0);
			if (streakTimeout) {
				clearTimeout(streakTimeout);
			}
			const newTimeout = setTimeout(() => {
				setOldStreak(currentStreak);
			}, 250);

			if (!isMuted && currentStreak > 1) {
				const newTimeout = setTimeout(() => {
					audioPlayerRef.current.audio.current.play();
				}, 500);
				setStreakSoundTimeout(newTimeout);
			}

			setStreakTimeout(newTimeout);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [currentStreak]);

	// Set current points to points on mount
	useEffect(() => {
		setCurrentPoints(points);
		setIsFirstRender(false);
		if (!isMuted && audioPlayerRef) {
			audioPlayerRef.current.audio.current.pause();
		}
		// Clear intervals if any on unmount
		return () => {
			if (timeout) {
				clearTimeout(timeout);
			}
			if (streakTimeout) {
				clearTimeout(streakTimeout);
			}
			if (streakSoundTimeout) {
				clearTimeout(streakSoundTimeout);
			}
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className='Points'>
			{!isMuted &&
				<AudioPlayer
					ref={audioPlayerRef}
					muted={false}
					autoPlay={false}
					src={soundsData.multiplier}
					style={{display: 'none'}}
				/>
			}
			<div id={'trophyIndicator-' + type} 
				className={'Points-icon ' + type + (shouldTrophyAnimationRun ? ' pulseScale' : '')}/>
			<div className="Points-wrapper">
				{(currentStreak !== null && hasStreakIndicator) &&
					<div id='streakIndicator' 
						className={'Points-streakIndicatorWrapper pulseScale'}>
						<div className={'Points-streakIndicator streak' 
							+ (currentStreak > appConfig.maxStreak ? appConfig.maxStreak : currentStreak)}/>
					</div>
				}
				{(currentStreak !== null && !hasStreakIndicator) &&
					<div className={'Points-streakMarkerWrapper' + (oldStreak > 1 ? ' pulseScale' : '')}>
						<div className={'Points-streakMarker streak' 
							+ (currentStreak > appConfig.maxStreak ? appConfig.maxStreak : currentStreak)
							+ (oldStreak > 1 ? ' hasStreak' : '')}/>
					</div>
				}
				<div className='Points-window'>
					<div className='Points-title'>
						<span>{name}</span>
					</div>
					<div className='Points-number'>
						<span>{currentPoints}</span>
					</div>
				</div>
			</div>
		</div>
	);
};

Points.propTypes = {
	type: PropTypes.string.isRequired,
	name: PropTypes.string.isRequired,
	points: PropTypes.number.isRequired,
	currentStreak: PropTypes.number,
	hasStreakIndicator: PropTypes.bool,
	isMuted: PropTypes.bool,
};

export default Points;
