const gamePagesData = {
	lobby: {
		tabletPage: 'lobby',
		projectorPage: 'pauseScreen',
	},
	assignment: {
		tabletPage: 'assignment',
		projectorPage: 'buildings',
	}
};

module.exports = {gamePagesData};