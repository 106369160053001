import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './highscore.scss';
import { pageUiTexts } from 'data/ui-texts';

const Highscore = ({highscoreList, showSmall = false}) => {
	// Used for comparison with highscoreList prop
	const [scorelist, setScorelist] = useState([]);

	// Initialize scorelist on mount
	useEffect(() => {
		setScorelist(highscoreList);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// When new highscorelist update, updates current and old score lists
	useEffect(() => {
		scorelist.forEach((score, index) => {
			// Check whether name or value changed at index, or whether index is new
			if (highscoreList[index] &&
				(highscoreList[index].score !== score.score || highscoreList[index].name !== score.name)) {

				// Find corresponding animation for changed index
				const animation = document.getAnimations().find((anim) => {
					const attribute = anim.effect.target.attributes.getNamedItem('index');
					
					return attribute && attribute.value === index.toString();
				});
				
				// Retrigger animation
				if (animation) {
					animation.cancel();
					animation.play();
				}
			}
		});

		// Update scorelist
		setScorelist(highscoreList);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [highscoreList]);

	return (
		<div className={'Highscore' + (showSmall ? ' isSmall' : '')}>
			{/* Header */}
			<div className='Highscore-header'>
				<div className='Highscore-title'>
					<span>{pageUiTexts.bestOfTheDay}</span>
				</div>
				<div className='Highscore-trophyIcon'/>
			</div>
			{/* Highscore list */}
			<div id='highscoreList' className='Highscore-list'>
				{scorelist.map((item, index) => {
					return (
						<div key={index} index={index} className='Highscore-item'>
							<div className='Highscore-placingWrapper'>
								<div className='Highscore-placing'><span>{index + 1}</span></div>
								<div className={'Highscore-name' + (showSmall ? ' isSmall' : '')}>
									<span>{item.name}</span>
								</div>
							</div>
							<div className='Highscore-score'><span>{item.score}</span></div>
						</div>
					);
				})}
			</div>
		</div>
	);
};

Highscore.propTypes = {
	highscoreList: PropTypes.array.isRequired,
	showSmall: PropTypes.bool,
};

export default Highscore;