/* Multiple choice */
const multipleChoiceTemplate = {
	mainTemplate: {
		type: 'multiple-choice',
		isSolveToContinue: true,
		isDilemma: false,
		subtype: null, // null, images
		showNumberOfAnswersToSelect: false, // set to false to hide the "choose x answers"
		numberOfAnswersToSelect: 1, // used if isDilemma
		layout: null, // used if subtype is 'images'
		shuffleOptions: true,
		image: null,
		title: '',
		text: '',
		options: [],
	},
	optionTemplate: {
		text: '', 
		isCorrect: false,
	}
};

export {
	multipleChoiceTemplate,
};
