import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import appConfig from 'config/app.config';
import apiHelper from 'helpers/api-helper';
import { adminUiTexts, generalUiTexts } from 'data/ui-texts';
import Button from 'components/ui/button/button';
import './admin.scss';

const Admin = ({userId, handleLogout }) => {
	/* Games (from database) */
	const [games, setGames] = useState([]);

	/* New game id / isLoading / feedback */
	const [newGameId, setNewGameId] = useState('');
	const [isLoading, setIsLoading] = useState(false); 
	const [feedback, setFeedback] = useState(null);

	/**
	 * Component did mount / will unmount
	 */
	useEffect(() => {
		/* Subscribe to games */
		const db = firebase.firestore();
		const unsubscribeGames = db.collection(appConfig.gamesDbName).onSnapshot(
			(querySnapshot) => {
				let dbGames = [];
				querySnapshot.forEach((doc) => {dbGames.push({id: doc.id, ...doc.data()});});
				setGames(dbGames);
			},
			(error) => {
				console.error('could not get games: ', error);
			}
		);

		return () => {
			/* Cancel subscriptions */
			unsubscribeGames();
		};
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Input new game id
	 */ 
	const updateNewGameId = (event) => {
		setNewGameId(event.target.value);
	};

	/**
	 * Add new game
	 */
	const handleAddNewGame = () => {
		if (newGameId && newGameId.length > 0) {
			const formattedNewGameId = newGameId.toLowerCase().replace(/[ ]+/g, '-').replace(/[^a-z0-9-]+/g, '');
			setNewGameId(formattedNewGameId);
			if (games.some((g) => {return g.id === formattedNewGameId;})) {
				setFeedback(adminUiTexts.gameIdAlreadyExists);
			} else {
				setIsLoading(true);
				
				createNewGame(formattedNewGameId).then((response) => {
					console.log(response);
					if (response.status !== 'success') {
						setFeedback(adminUiTexts.couldNotCreateGame);
					} else {
						setNewGameId('');
					}
					setIsLoading(false);
				});
			}
		}
	};

	/**
	 * Create new game
	 * @param {string} gameId 
	 * @returns 
	 */
	const createNewGame = (gameId) => {
		if (isLoading) return;

		return new Promise((resolve) => {
			apiHelper('facilitator/create-game', {
				gameId: gameId, 
			}).then(
				(response) => {
					if (response.status === 'success') {
						// Success
						resolve({status: 'success'});
					} else {
						// Error
						resolve({status: 'error', error: response.error});
					}
				},
				(rejection) => {
					// Error
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};



	return (
		<div className="Admin">
			<div className="Admin-logoutBtn">
				<Button 
					classes={['logout']} 
					text={generalUiTexts.logout} 
					onClick={() => {handleLogout();}}
				/>
			</div>
			<div className="Admin-addGame">
				<div className="Admin-addGameInput">
					<input
						name="gameId"
						type="text"
						placeholder={adminUiTexts.gameIdLabel}
						value={newGameId ? newGameId : ''}
						onChange={(event) => {updateNewGameId(event);}}
					/>
				</div>
				<div className="Admin-addGameBtn">
					<Button 
						isLoading={isLoading}
						classes={['login']}
						text={adminUiTexts.createGame}
						onClick={() => {handleAddNewGame();}}
					/>
				</div>
				<div className="Admin-addGameFeedback">
					<span>{feedback}</span>
				</div>
			</div>
			<div className="Admin-games">
				<div className="Admin-gamesTitle"><span>{adminUiTexts.games}:</span></div>
				{games.map((game) => {
					return (
						<div key={game.id} className="Admin-game"><span>{game.id}</span></div>
					);
				})}
			</div>
		</div>
	);
};

Admin.propTypes = {
	userId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired,
};

export default Admin;
