import React from 'react';
import PropTypes from 'prop-types';
import {generalUiTexts} from 'data/ui-texts';
import './loading.scss';
import Button from 'components/ui/button/button';

const Loading = ({loadErrMsg, handleLogout}) => {

	const loadingText = (loadErrMsg ? loadErrMsg : generalUiTexts.loading);

	return (
		<div className="Loading">
			<div className="Loading-panel" >
				<div className="Loading-logoutBtn" > 
					<Button
						text={generalUiTexts.logout}
						classes={['logout']}
						onClick={() => {handleLogout();}}
					/>
				</div>
				<div className="Loading-content">
					<div className="Loading-circles">
						<div className="Loading-circle" />
						<div className="Loading-circle" />
						<div className="Loading-circle" />
					</div>
					<div className="Loading-text">{loadingText}</div>
				</div>
			</div>
		</div>
	);
};

Loading.propTypes = {
	loadErrMsg: PropTypes.string,
	handleLogout: PropTypes.func.isRequired,
};

export default Loading;
