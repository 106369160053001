let env = process.env.REACT_APP_ENV ? process.env.REACT_APP_ENV : process.env.NODE_ENV;
if (!env) {
	console.error('ENV: ', env);
	env = 'development';
}

let usersDbName = 'users';
let gamesDbName = 'games';
let playersDbName = 'players';
let gameUrl = 'localhost:3000';
let apiURL = 'http://localhost:8080/api/';
if (env === 'test' || env === 'demo') {
	gameUrl = 'cgl-messespil-test.web.app';
	apiURL = 'https://cgl-messespil-test.web.app/api/';
	if (env === 'demo') {
		gameUrl = 'cgl-messespil-demo.web.app';
		apiURL = 'https://cgl-messespil-demo.web.app/api/';
		gamesDbName = 'games-demo';
		playersDbName = 'players-demo';
	}
}
if (env === 'production') {
	gameUrl = 'cgl-messespil-production.web.app';
	apiURL = 'https://cgl-messespil-production.web.app/api/';
}

const appConfig = {
	env: env,
	apiURL: apiURL,
	gameUrl: gameUrl,
	usersDbName: usersDbName,
	gamesDbName: gamesDbName,
	playersDbName: playersDbName,
	numberOfFeedbackOptionsToSelect: 2,
	gameCountdownSeconds: 6,
	gameDurationSeconds: 120,
	gameCooldownSeconds: 10,
	maxStreak: 4,
	scoreGainPerTask: 10,
	maxNameLength: 8,
};

export default appConfig;