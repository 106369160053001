import React from 'react';
import './game-over-projector.scss';
import PropTypes from 'prop-types';
import { gameUiTexts, popupsData } from 'data/ui-texts';
import AudioPlayer from 'react-h5-audio-player';
import { soundsData } from 'data/sound-data';

const GameOverProjector = ({player = null, players, timeLeft = null, isMuted}) => {
	const winner = players[0].points > players[1].points ? players[0] : players[1];

	let isWinner = true;
	if (player && winner.id !== player.id) {
		isWinner = false;
	}

	return (
		<div className='GameOverProjector'>
			{!isMuted &&
				<AudioPlayer
					loop={true}
					muted={false}
					autoPlay={true}
					src={soundsData.crowd}
					style={{display: 'none'}}
				/>
			}
			<div className='GameOverProjector-infoWrapper'>
				<div className='GameOverProjector-trophyWrapper'>
					<div className='GameOverProjector-trophyGlow'/>
					<div className='GameOverProjector-trophyIcon'/>
				</div>
				<div className='GameOverProjector-messageWrapper'>
					<div className='GameOverProjector-glow'/>
					<div className='GameOverProjector-winner'>
						{isWinner ? 
							<span>{winner.name + ' ' + popupsData.won}</span>
							:
							<span>{player.name + ' ' + popupsData.didNotWin}</span>
						}
					</div>
					<div className='GameOverProjector-gameResetMessage'>
						<span>
							{
								gameUiTexts.gameReturnsIn1
								+ (timeLeft ? timeLeft : 0)
								+ gameUiTexts.gameReturnsIn2
							}
						</span>
					</div>
				</div>
			</div>
		</div>
	);
};

GameOverProjector.propTypes = {
	player: PropTypes.object,
	players: PropTypes.array.isRequired,
	timeLeft: PropTypes.number,
	isMuted: PropTypes.bool.isRequired,
};

export default GameOverProjector;