import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Logo from 'components/ui/logo/logo';
import './pause-screen.scss';
import Highscore from 'components/ui/highscore/highscore';
import { isFullScreen, toggleFullScreen } from 'helpers/screen-helper';
import { isToday } from 'helpers/time-helper';
import Button from 'components/ui/button/button';
import { pageUiTexts, generalUiTexts, loginUiTexts, gameUiTexts } from 'data/ui-texts';

const PauseScreen = ({ players, game, updateGame, handleLogout, timeLeft = null, resetGame, isMuted, toggleMute }) => {

	const [isFull, setIsFull] = useState(isFullScreen());
	const [airplaneBannerTextIndex, setAirplaneBannerTextIndext] = useState(0);

	window.matchMedia('(display-mode: fullscreen)').addEventListener('change', (event) => {
		setIsFull(event.matches);
	});

	useEffect(()=>{
		/* no highscore return */
		if (game && game.highscoreList.length === 0) {
			return;
		}
		/* if resetHighscoreDate does not exsist create it */
		if (!game.resetHighscoreDate) {
			updateGame({resetHighscoreDate: new Date().toDateString()});
		} else if (!isToday(game.resetHighscoreDate)) {
			/* update the highscore and with new date */
			updateGame({highscoreList: [], resetHighscoreDate: new Date().toDateString()});
		}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	
	/**
	 * Toggles between full screen and minimized
	 */
	const handleToggleFullScreen = () => {
		toggleFullScreen();
	};

	/**
	 * Switches text index for aiplane banner text, on iteration end, if animation is 'slideInFromLeft'
	 * @param {object} event 
	 */
	const handleAirplaneIterationEnd = (event) => {
		if (event.animationName === 'slideInFromLeft') {
			let currentIndex = airplaneBannerTextIndex + 1;
			if (currentIndex > gameUiTexts.airplaneBanner.length - 1) {
				currentIndex = 0;
			}

			setAirplaneBannerTextIndext(currentIndex);
		}
	};

	return (
		<div className='PauseScreen'>

			<div className='PauseScreen-navigation'>
				<Button
					text={generalUiTexts.logout}
					classes={['flatBlue']}
					onClick={() => {handleLogout();}}
				/>
			</div>
			<div className='PauseScreen-resetButton'>
				<Button
					text={pageUiTexts.reset}
					classes={['flatBlue']}
					onClick={() => {resetGame();}}
				/>
			</div>
			<div className='PauseScreen-airplaneWrapper'
				onAnimationIteration={(event) => {handleAirplaneIterationEnd(event);}}>
				<div className='PauseScreen-airplaneMovement'>
					
					<div className='PauseScreen-airplane'/>
					<div className='PauseScreen-airplaneText'>
						<span>{gameUiTexts.airplaneBanner[airplaneBannerTextIndex]}</span>
					</div>
				</div>
			</div>
			
			<div className={'PauseScreen-muteToggle' + (isMuted ? ' mute' : '')} 
				onClick={() => {toggleMute();}}/>

			<div className={'PauseScreen-fullscreenToggle' + (isFull ? ' fullScreen' : '')} 
				onClick={() => {handleToggleFullScreen();}}/>

			<div className='PauseScreen-logo'>
				<Logo type="projector-pause" />
			</div>
			{timeLeft ? 
				<div className='PauseScreen-countdown'>
					<div className='PauseScreen-gameStart'><span>{generalUiTexts.gameStartsIn}</span></div>
					<div className='PauseScreen-countDownNumber'><span>{timeLeft}</span></div>
				</div>
				:
				<div className='PauseScreen-highscore'>
					{game.highscoreList.length > 0 && <Highscore highscoreList={game.highscoreList}/>}
				</div>
			}

			{players.map((player, index) => {
				return (
					<div key={index} className={'PauseScreen-player player' + player.id}>
						<div className='PauseScreen-playerHeader'>
							<span>{generalUiTexts.player + ' ' + player.id + ':'}</span>
						</div>
						<div className={'PauseScreen-playerIconWrapper player' + player.id
							+ (player.isReady ? ' readyAnimate' : '')}>
							<div className={'PauseScreen-playerIcon player' + player.id 
							+ (player.name ? ' animate' : '')}/>
						</div>
						<div className={'PauseScreen-playerName player' + player.id 
							+ (player.isReady ? ' isReady' : '')
							+ (player.name ? '' : ' noInput')}>
							<span>{player.name ? player.name : loginUiTexts.waitingFor1}</span>
							{!player.name &&
								<div className='PauseScreen-textCursor'>
									<span>|</span>
								</div>
							}
						</div>
					</div>
				);
			})}
		</div>
	);
};
PauseScreen.propTypes = {
	game: PropTypes.object,
	updateGame: PropTypes.func.isRequired,
	handleLogout: PropTypes.func.isRequired,
	resetGame: PropTypes.func.isRequired,
	players: PropTypes.array.isRequired,
	timeLeft: PropTypes.number,
	isMuted: PropTypes.bool.isRequired,
	toggleMute: PropTypes.func.isRequired,
};
export default PauseScreen;