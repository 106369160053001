import React from 'react';
import PropTypes from 'prop-types';
import './name-plate.scss';

const NamePlate = ({ name, playerId }) => {
	return (
		<div className='NamePlate'>
			<div className='NamePlate-background'>
				<span>{name}</span>
			</div>
			<div className={'NamePlate-userIcon ' + (playerId ? `NamePlate-userIcon-${playerId}` : '')}/>
		</div>
	);
};

NamePlate.propTypes = {
	name: PropTypes.string.isRequired,
	playerId: PropTypes.number,
};

export default NamePlate;