import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './lobby.scss';
import Button from 'components/ui/button/button';
import { errorUiTexts, loginUiTexts, pageUiTexts } from 'data/ui-texts';
import Logo from 'components/ui/logo/logo';
import NamePlate from 'components/ui/name-plate/name-plate';
import { setFullScreen } from 'helpers/screen-helper';

const Lobby = ({player, players, updatePlayer, timeLeft = null}) => {
	const [name, setName] = useState('');
	const [otherPlayer, setOtherPlayer] = useState(null);

	// Handles in case of already existing player name on database
	useEffect(() => {
		if (player.name) {
			setName(player.name);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	/**
	 * Sets other player depending on current player
	 */
	useEffect(() => {
		if (player) {
			switch (player.id) {
			case 1: 
				setOtherPlayer(players[1]);
				break;
			case 2: 
				setOtherPlayer(players[0]);
				break;
			default:
				console.error(errorUiTexts.unknownPlayer);
				break;
			}
		}
	}, [player, players]);

	// Updates player name in database and updates state
	const handleChoosePlayerName = () => {
		updatePlayer({name: name});
	};

	// Toggles whether player is ready in database
	const toggleReadyPlayer = () => {
		updatePlayer({isReady: !player.isReady});
	};

	const handleInput = (event) => {
		let value = event.target.value;

		if (value.length <= 8) {
			setName(value);
		}
	};

	return (
		<div className='Lobby'>
			<div className={'Lobby-logo' + (player.name ? ' ready' : '')}>
				<Logo />
			</div>
			{player.name && 
				<div className={'Lobby-name'}>
					<NamePlate name={name} playerId={player.id}/>
				</div>
			}
			<div className='Lobby-nameChoice'>
				<div className='Lobby-gameGuide'>
					<span>{loginUiTexts.gameGuide}</span>
				</div>
				{player.name ?
					<div className='Lobby-waitingForPlayerTwo'>
						<div className={'Lobby-player' + (player.isReady ? ' isReadyPlayer' + player.id : '')}>
							<div className='Lobby-playerName'>
								<span>
									{name}
								</span>
							</div>
							<div className={'Lobby-userIcon ' + (player ? `Lobby-userIcon-${player.id}` : '')}/>
						</div>
						<div className={'Lobby-player' + 
							((otherPlayer && otherPlayer.isReady) ? ' isReadyPlayer' + otherPlayer.id : '')}>
							<div className='Lobby-playerName'>
								<span>
									{otherPlayer && otherPlayer.name ? otherPlayer.name : ''}
								</span>
							</div>
							<div className={'Lobby-userIcon' + 
							(otherPlayer && otherPlayer.name ? '' : ' noName') +
							(otherPlayer && ' Lobby-userIcon-' + otherPlayer.id)}/>
						</div>
						{(timeLeft === null && otherPlayer && otherPlayer.name) ?
							<div className='Lobby-readyButton'>
								<Button 
									isDisabled={player.isReady && otherPlayer.isReady}
									text={player.isReady ? pageUiTexts.cancel : pageUiTexts.groupReady}
									classes={['flatBlue']}
									onClick={() => {toggleReadyPlayer();}}
								/>
							</div>
							:
							<div className='Lobby-timeLeft'>
								<span>
									{/* If countdown is set, we show countdown text */}
									{timeLeft !== null ? 
										loginUiTexts.countDown1 + 
										timeLeft + 
										loginUiTexts.countDown2 : ' '}
								</span>
								<span>
									{/* If the other player is not ready yet, we show waiting text */}
									{otherPlayer && !otherPlayer.name ? 
										loginUiTexts.waitingFor1 + 
										otherPlayer.id + 
										loginUiTexts.waitingFor2 : ''}
								</span>
							</div>
						}
					</div>
					:
					// Input name
					<div className='Lobby-inputWrapper'>
						<div className='Lobby-title'>
							<span>{loginUiTexts.insertName}</span>
							<div className={'Lobby-userIcon ' + (player ? `Lobby-userIcon-${player.id}` : '')}/>	
						</div>
						<input
							className='Lobby-input'
							name="name"
							type="text"
							placeholder={'Navn'}
							value={name} 
							onChange={(event)=>{handleInput(event);}}
						/>
						<div className='Lobby-startButton'>
							<Button 
								isDisabled={name === ''}
								classes={['flatBlue']}
								text={loginUiTexts.start}
								onClick={() => {handleChoosePlayerName(); setFullScreen();}}
								type='submit'
							/>
						</div>
					</div>
				}
			</div>
		</div>
	);
};

Lobby.propTypes = {
	player: PropTypes.object.isRequired,
	players: PropTypes.array.isRequired,
	updatePlayer: PropTypes.func.isRequired,
	timeLeft: PropTypes.number,
};

export default Lobby;