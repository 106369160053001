import appConfig from 'config/app.config';

// Calculates new score based on current streak and score.
function calculateNewScore(currentScore, currentStreak) {
	let newScore = currentScore + (appConfig.scoreGainPerTask * currentStreak);

	return newScore;
}

// Calculate streak
function calculateStreak(currentStreak) {
	let newStreak = currentStreak + 1;

	if (newStreak > appConfig.maxStreak) {
		newStreak = appConfig.maxStreak;
	}

	return newStreak;
}

export {
	calculateNewScore,
	calculateStreak,
};