/**
 * Toggles full screen
 */
function toggleFullScreen() {
	const doc = document;
	const elem = document.documentElement;
	if ((doc.fullScreenElement && doc.fullScreenElement !== null) ||
	(!doc.mozFullScreen && !doc.webkitIsFullScreen)) {
		if (elem.requestFullscreen) { /* General, not fully supported on all browser versions (mostly Safari on IOS) */
			elem.requestFullscreen();
		} else if (elem.mozRequestFullScreen) { /* Fallback for firefox */
			elem.mozRequestFullScreen();
		} else if (elem.webkitRequestFullScreen) { /* Fallback for Safari */
			elem.webkitRequestFullScreen(elem.ALLOW_KEYBOARD_INPUT);
		}
	} else {
		if (doc.exitFullscreen) {
			doc.exitFullscreen();
		} else if (doc.mozCancelFullScreen) { /* Fallback for firefox */
			doc.mozCancelFullScreen();
		} else if (doc.webkitCancelFullScreen) { /* Fallback for Safari */
			doc.webkitCancelFullScreen();
		}
	}
}

/**
 * Set fullscreen
 */
function setFullScreen() {
	const elem = document.documentElement;
	if (elem.requestFullscreen) { /* General, not fully supported on all browser versions (mostly Safari on IOS) */
		elem.requestFullscreen();
	} else if (elem.mozRequestFullScreen) { /* Fallback for firefox */
		elem.mozRequestFullScreen();
	} else if (elem.webkitRequestFullScreen) { /* Fallback for Safari */
		elem.webkitRequestFullScreen(elem.ALLOW_KEYBOARD_INPUT);
	}
}

/**
 * Checks whether currently full screen
 * @returns return true/false
 */
function isFullScreen() {
	const doc = document;
	if (doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreen) {
		return true;
	}

	return false;
}

export {
	toggleFullScreen,
	setFullScreen,
	isFullScreen
};