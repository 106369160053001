import {useEffect} from 'react';
import firebaseInit from 'firebase-init';
import './app.scss';
import AdminLoginController from './admin/admin-login-controller';
import LoginController from './login/login-controller';

function App() {
	/**
	 * Component mounted / will unmount
	 */
	useEffect(() => {
		/* Component mounted */

		/* Initialize firebase */
		firebaseInit();

		/* Component will ummount */
		// return () => {};
	});


	if (window.location.pathname.toLowerCase().includes('admin')) {
		return (
			<div className="App">
				<AdminLoginController />
			</div>
		);
	}

	return (
		<div className="App">
			<LoginController/>
		</div>
	);
}

export default App;
