import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import {shuffleArray} from 'helpers/array-helper';
import TaskIntro from 'components/game/module/task-intro/task-intro';
import './multiple-choice.scss';
import Button from 'components/ui/button/button';

const MultipleChoice = (props) => {
	const {
		taskData,
		handleCompleteTask,
	} = props;

	/* Animate selected options */
	const [animateSelectedOptions, setAnimateSelectedOptions] = useState(false);

	const [optionsStyle, setOptionsStyle] = useState(null);

	/* Track available and selected options */
	const [optionIds, setOptionIds] = useState([]);
	const [selectedOptionIds, setSelectedOptionIds] = useState([]);
	
	/* Track whether player has selected an answer yet */
	const [hasAnswered, setHasAnswered] = useState(false);

	/* Get number of correct answers */
	const numberOfCorrectAnswers = (taskData.isDilemma
		? taskData.numberOfAnswersToSelect
		: taskData.options ? taskData.options.filter((o) => {return o.isCorrect === true;}).length : 0
	);

	/**
	 * Adjust height of options container with respect to task intro
	 */
	const getOptionsStyle = () => {
		let style = null;
		const taskIntroElement = document.getElementById('taskIntro');
		if (taskIntroElement) {
			const offsetTop = taskIntroElement.clientHeight + taskIntroElement.offsetTop;
			style = {position: 'absolute', bottom: '0', top: offsetTop + 'px'};
		}
		return style;
	};

	/**
	 * Get option ids, shuffle them
	 * @returns {array} optionIds
	 */
	const getOptionIds = () => {
		if (!taskData.options) return [];
		if (taskData.hasOwnProperty('shuffleOptions') && !taskData.shuffleOptions) {
			return taskData.options.map((option) => {return option.id;});
		}
		return shuffleArray(taskData.options.map((option) => {return option.id;}));
	};
	
	/* Update selected items if new task */
	useEffect(() => {
		setOptionIds(getOptionIds());
		setOptionsStyle(getOptionsStyle());
		setAnimateSelectedOptions(false);
		setHasAnswered(false);
		setSelectedOptionIds([]);

		const optionsElement = document.getElementById('multipleChoiceOptions');
		if (optionsElement) optionsElement.scrollTop = 0;
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [taskData.id]);

	/**
	 * Select option
	 * @param {number} optionId 
	 * @returns 
	 */
	const selectOptionId = (optionId) => {
		/* Already selected */
		const optionIndex = selectedOptionIds.indexOf(optionId);
		if (optionIndex >= 0) return;

		/* Enable animation */
		setAnimateSelectedOptions(true);

		/* Select option */
		let newSelectedOptionIds = JSON.parse(JSON.stringify(selectedOptionIds));
		newSelectedOptionIds.push(optionId);
		setSelectedOptionIds(newSelectedOptionIds);

		let isCorrect = true;
		const optionData = taskData.options.find((option) => {return option.id === optionId;});
		if (!optionData) return null;
		
		if (!taskData.isDilemma && optionData.hasOwnProperty('isCorrect')) {
			isCorrect = optionData.isCorrect;
		}
		
		/* Complete task */
		completeTask(isCorrect);
	};

	/**
	 * Complete task
	 * @param {bool} isCorrect
	 */
	const completeTask = (isCorrect) => {
		setHasAnswered(true);

		/* Save completed task */
		handleCompleteTask(
			isCorrect
		);
	};

	return (
		<div className={'MultipleChoice '
			+ (taskData.isDilemma ? ' dilemma' : '')
			+ (taskData.subtype ? ' ' + taskData.subtype : '') 
			+ (taskData.layout ? ' ' + taskData.layout : '')
		}
		>
			<div id="taskIntro" className="MultipleChoice-intro">
				<TaskIntro 
					showNumberOfAnswersToSelect={taskData.showNumberOfAnswersToSelect}
					numberOfCorrectAnswers={numberOfCorrectAnswers}
					title={taskData.title}
					text={taskData.text}
					image={taskData.image}
				/>
			</div>
			<div id="multipleChoiceOptions" className="MultipleChoice-options" style={optionsStyle}>
				
				<div className={'MultipleChoice-optionsWrap'}>
					{optionIds.map((optionId) => {
						const optionData = taskData.options.find((option) => {return option.id === optionId;});
						if (!optionData) return null;

						const isSelected = selectedOptionIds.indexOf(optionData.id) >= 0;
						let optionClass = ['multipleChoice'];
						
						if (isSelected) optionClass.push('selected');
						
						if (isSelected && !taskData.isDilemma && optionData.hasOwnProperty('isCorrect')) {
							if (optionData.isCorrect) {
								optionClass.push(animateSelectedOptions ? 'animateCorrect' : 'correct');
							} else {
								optionClass.push(animateSelectedOptions ? 'animateWrong' : 'wrong');
							}
						}

						return (
							<div 
								key={optionData.id} 
								className={'MultipleChoice-option'} 
							>
								<Button
									isDisabled={hasAnswered}
									text={optionData.text}
									classes={optionClass}
									onClick={() => {selectOptionId(optionData.id);}}	
								/>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

MultipleChoice.propTypes = {
	taskData: PropTypes.object.isRequired,
	handleCompleteTask: PropTypes.func.isRequired,
};

export default MultipleChoice;
