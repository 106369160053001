import React, { useEffect, useState } from 'react';
import './buildings.scss';
import PropTypes from 'prop-types';
import { calculateRank } from 'helpers/rank-helper';
import Logo from 'components/ui/logo/logo';
import Highscore from 'components/ui/highscore/highscore';
import Points from 'components/ui/points/points';
import Timer from 'components/ui/timer/timer';
import Building from 'components/ui/building/building';
import AudioPlayer from 'react-h5-audio-player';
import { soundsData } from 'data/sound-data';
import Button from 'components/ui/button/button';
import { pageUiTexts } from 'data/ui-texts';

const Buildings = ({ game, players, resetGame, timeLeft, isShowingGameOver, isMuted }) => {
	// Keeps track of current rank of both players
	const [currentPlayersRanks, setCurrentPlayersRanks] = useState([]);
	const playerOne = players[0];
	const playerTwo = players[1];

	// Music volume
	const volume = 0.25;

	/**
	 * Updates current for both players, on init
	 */
	useEffect(() => {
		// If current ranks are not set, initialize them
		if (currentPlayersRanks.length === 0) {
			const calculatedPlayersRanks = [];
			players.forEach((player) => {
				let playerRank = calculateRank(player.points);
				calculatedPlayersRanks.push(playerRank);
			});
			setCurrentPlayersRanks(calculatedPlayersRanks);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// updating previous and current rank
	players.forEach((player) => {
		const playerIndex = player.id - 1;
		const playerRank = calculateRank(player.points);
		
		// For each player, check if rank has changed.
		if (playerRank !== currentPlayersRanks[playerIndex]) {
			// If yes, then update current rank for player
			currentPlayersRanks[playerIndex] = playerRank;
		}
	});

	return (
		<div className='Buildings'>
			{!isMuted &&
				<>
					<AudioPlayer
						muted={false}
						volume={volume}
						autoPlay={true}
						src={soundsData.secretCircuit}
						style={{display: 'none'}}
					/>
					<AudioPlayer
						muted={false}
						autoPlay={true}
						src={soundsData.start}
						style={{display: 'none'}}
					/>
				</>
			}
			<div className='Buildings-sidebar'>
				<div className='Buildings-logo'>
					<Logo/>
				</div>
				{timeLeft !== null && 
					<Timer totalSeconds={timeLeft}/>
				}
				<div className='Buildings-resetButton'>
					<Button
						text={pageUiTexts.reset}
						classes={['flatBlue', 'lowOpacity']}
						onClick={() => {resetGame();}}
					/>
				</div>
			</div>
			<div className={'Buildings-points player-1'}>
				<Points
					type={'player-1'}
					name={playerOne.name}
					points={playerOne.points}
					currentStreak={playerOne.streak}
					hasStreakIndicator={false}
					isMuted={isMuted}
				/>
			</div>
			<div className={'Buildings-points player-2'}>
				<Points
					type={'player-2'}
					name={playerTwo.name}
					points={playerTwo.points}
					currentStreak={playerTwo.streak}
					hasStreakIndicator={false}
					isMuted={isMuted}
				/>
			</div>

			<div className='Buildings-buildingsWrapper'>
				{players.map((player, index) => {
					let isWinner = false;
					if (isShowingGameOver) {
						const winnerId = playerOne.points > playerTwo.points ? playerOne.id : playerTwo.id;
						isWinner = player.id === winnerId;
					}

					return (
						<div key={index}
							className={'Buildings-rankBuilding' 
								+ (isShowingGameOver && isWinner ? ' animated' : '')}>
							<Building 
								rank={currentPlayersRanks[index]} 
								player={player}
								isMuted={isMuted}
							/>
						</div>
					);
				})}
			</div>

			<div className='Buildings-highscore'>
				<Highscore showSmall={true} highscoreList={game.highscoreList ? game.highscoreList : []}/>
			</div>
			<div className='Buildings-spacer'/>
		</div>
	);
};
Buildings.propTypes = {
	game: PropTypes.object.isRequired,
	players: PropTypes.array.isRequired,
	resetGame: PropTypes.func.isRequired,
	timeLeft: PropTypes.number,
	isShowingGameOver: PropTypes.bool.isRequired,
	isMuted: PropTypes.bool.isRequired,
};
export default Buildings;