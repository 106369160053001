import appConfig from 'config/app.config';
import { tasks } from 'data/module-tasks/tasks';
import { ranksData } from 'data/rank-data';

/**
 * Calculates rank based on score.
 * @param {number} score 
 */
function calculateRank(score) {
	let finalRank = 0;
	const maxPossibleScore = calculateMaxScore();
	const scorePercentage = score / maxPossibleScore * 100;
	// Loop through rank data and check whether score is higher than the current rank threshold
	for (const rank of ranksData) {
		// RanksData is ordered descendingly so we can stop early here
		if (scorePercentage >= rank.threshold) {
			finalRank = rank.id;
			break;
		}
	};

	return finalRank;
}

/**
 * Calculates the maximum possible score
 * @returns 
 */
function calculateMaxScore() {
	const taskAmount = tasks.length;
	const maxPointPerTask = appConfig.maxStreak * appConfig.scoreGainPerTask;
	// Amount of tasks possible with full streak
	const maxFullStreakTaskAmount = (taskAmount - appConfig.maxStreak - 1);

	// Amount of points gained while reaching max streak.
	let streakRunupValue = 0;
	for (let i = 0; i < appConfig.maxStreak; i++) {
		streakRunupValue += i * appConfig.scoreGainPerTask;
	}

	return maxPointPerTask * maxFullStreakTaskAmount + streakRunupValue;
}

export {
	calculateRank,
	calculateMaxScore
};