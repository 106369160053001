import React from 'react';
import './game-over.scss';
import PropTypes from 'prop-types';
import { gameUiTexts, popupsData } from 'data/ui-texts';
import Button from '../button/button';
import ConfettiCannon from '../confetti-cannon/confetti-cannon';

const GameOver = ({handleClose, player = null, players, timeLeft = null}) => {
	const winner = players[0].points > players[1].points ? players[0] : players[1];

	let isWinner = true;
	if (player && winner.id !== player.id) {
		isWinner = false;
	}

	return (
		<div className='GameOver'>
			<div className='GameOver-infoWrapper'>
				<div className='GameOver-trophyIcon'/>
				<div className='GameOver-winner'>
					{isWinner ? 
						<span>{winner.name + ' ' + popupsData.won}</span>
						:
						<span>{player.name + ' ' + popupsData.didNotWin}</span>
					}
				</div>
				<div className='GameOver-gameResetMessage'>
					<span>{gameUiTexts.gameReturnsIn1 + (timeLeft ? timeLeft : 0) + gameUiTexts.gameReturnsIn2}</span>
				</div>
				<div className='GameOver-resetButton'>
					<Button
						text={gameUiTexts.resetGame}
						onClick={() => {handleClose();}}
						classes={['flatBlue']}
					/>
				</div>
			</div>
			{isWinner && 
				<ConfettiCannon 
					colors={['#EB1CB5', '#4DB4FC', '#60CB4D', '#F85454', '#E3A626', '#7D64A8']}
				/>
			}
		</div>
	);
};

GameOver.propTypes = {
	handleClose: PropTypes.func.isRequired,
	player: PropTypes.object,
	players: PropTypes.array.isRequired,
	timeLeft: PropTypes.number,
};

export default GameOver;