import React, { Component } from 'react';
import PropTypes from 'prop-types';
import 'firebase/compat/firestore';
import Settings from 'components/settings/settings';
import firebase from 'firebase/compat/app';
import appConfig from 'config/app.config';
import Loading from 'components/loading/loading';
import GameController from 'components/game/game-controller';
import apiHelper from 'helpers/api-helper';
import { errorUiTexts } from 'data/ui-texts';

class FacilitatorController extends Component {
	constructor(props) {
		super(props);
		this.state = {
			page: 'settings',
			isLoading: true,
			loadErrMsg: null,
			userData: null,
			game: null,
			players: [],
			player: null,
			playerId: null,
			userType: null,
		};
		this.unsubscribeGame = null;
		this.unsubscribePlayer = null;
		this.unsubscribePlayers = null;
		this.timeout = null;
	}

	/**
	 * Component mounted
	 */
	componentDidMount = () => {
		/* Subscribe to game */
		this.subscribeToGame().then(() => {
			/* Subscribe to players */
			this.subscribeToPlayers(this.state.game.id).then(() => {
				this.setState({isLoading: false});
			});
		});
	};

	/**
	 * Component will unmount
	 */
	componentWillUnmount = () => {
		/* Clear timeout */
		if (this.timeout) clearTimeout(this.timeout);
	
		/* Unsubscribe from game / player / players */
		if (this.unsubscribeGame !== null) this.unsubscribeGame();
		if (this.unsubscribePlayer !== null) this.unsubscribePlayer();
		if (this.unsubscribePlayers !== null) this.unsubscribePlayers();
	};

	/**
	 * Called by the facilitator to subscribe to their game.
	 */
	subscribeToGame = () => {
		if (this.unsubscribeGame !== null) this.unsubscribeGame();

		return new Promise((resolve) => {
			const db = firebase.firestore();
			const query = db.collection(appConfig.gamesDbName).doc(this.props.gameId);
			this.unsubscribeGame = query.onSnapshot((doc) => {
				if (doc.exists) {
					/* Game exists, subscription successful */
					const game = {id: doc.id, ...doc.data()};
					this.setState({game: game}, () => {
						resolve({status: 'success'});
					});
				} else {
					/* Game does not exist, log out */
					this.props.handleLogout();
				}
			},
			(error) => {
				console.error('could not get game: ', error);
				resolve({status: 'error', error: error});
			}
			);
		});
	};

	/**
	 * Subscribe to the players of a game
	 * @param {string} gameId
	 */
	subscribeToPlayers = (gameId) => {
		if (this.unsubscribePlayers !== null) this.unsubscribePlayers();
		
		return new Promise((resolve) => {
			const db = firebase.firestore();
			this.unsubscribePlayers = db.collection(appConfig.playersDbName)
				.where('gameId', '==', gameId).onSnapshot(
					(querySnapshot) => {
						let players = [];
						querySnapshot.forEach((doc) => {players.push({id: doc.id, ...doc.data()});});
						this.setState({ players: players }, () => {
							resolve({status: 'success'});
						});
					},
					(error) => {
						console.error('could not get players: ', error);
						resolve({status: 'error', error: error});
					}
				);
		});
	};

	
	 /**
	 * Subscribe to the player of a game
	 * @param {number} playerId
	 */
	subscribeToPlayer = (playerId) => {
		if (this.unsubscribePlayer !== null) this.unsubscribePlayer();
		
		return new Promise((resolve) => {
			let db = firebase.firestore();
			this.unsubscribePlayer = db.collection(appConfig.playersDbName)
				.where('gameId', '==', this.state.game.id).where('id', '==', playerId).onSnapshot(
					(querySnapshot) => {
						let player = null;
						querySnapshot.forEach((doc) => {
							player = {id: doc.id, ...doc.data()};
						});

						this.setState({ player: player }, () => {
							resolve({status: 'success'});
						});
					},
					(error) => {
						console.error('could not get players: ', error);
						resolve({status: 'error', error: error});
					}
				);
		});
	};

	/**
	 * Update player
	 * @param {object} updates
	 * @returns {promise}
	 */
	updatePlayer = (updates, id = this.state.playerId) => {
		let playerId = this.props.gameId + '-' + id;
		let db = firebase.firestore();
		let playerRef = db.collection(appConfig.playersDbName).doc(playerId);
		return playerRef.update(updates);
	};

	/**
	 * Update game
	 * @param {object} updates
	 * @returns {promise}
	 */
	updateGame = (updates, id) => {
		let gameId = id ? id : this.state.game.id;
		let db = firebase.firestore();
		let gameRef = db.collection(appConfig.gamesDbName).doc(gameId);
		return gameRef.update(updates);
	};

	/**
	 * Reset game and players
	 */
	resetGame = () => {
		return new Promise((resolve) => {
			apiHelper('facilitator/reset-game', {
				gameId: this.state.game.id, 
			}).then(
				() => {
					// Success
					resolve({status: 'success'});
				},
				(rejection) => {
					// Error
					console.error('Error creating game: ', rejection);
					resolve({status: 'error', error: rejection});
				}
			);
		});
	};


	/**
	 * Choose projector mode
	 */
	chooseProjector = () => {
		this.setState({page: 'game', userType: 'projector'});
	};

	/**
	 * Subscribes to chosen player and sets playerType.
	 * @param {number} playerId 
	 */
	choosePlayer = (playerId) => {
		this.subscribeToPlayer(playerId).then(
			(response)=>{
				if (response.status === 'success') {
					this.setState({page: 'game', userType: 'player', playerId: playerId});
				}
			},
			(error) => {
				console.error(error);
				this.setState({
					feedback: errorUiTexts.unknownError,
				});
			}
		);
	};


	/**
	 * Render component
	 */
	render() {
		if (this.state.isLoading) {
			return (
				<Loading 
					loadErrMsg={this.state.loadErrMsg}
					handleLogout={this.props.handleLogout} 
				/>
			);
		};
		if (this.state.page === 'game') {
			return (
				<GameController
					isPlayer={this.state.userType === 'player'}
					updateGame={this.updateGame}
					updatePlayer={this.updatePlayer}
					game={this.state.game}
					players={this.state.players}
					player={this.state.player}
					resetGame={this.resetGame}
					handleLogout={this.props.handleLogout}
				/>
			);
		}
		return (
			<Settings
				choosePlayer={this.choosePlayer}
				game={this.state.game}
				players={this.state.players}
				chooseProjector={this.chooseProjector}
				resetGame={this.resetGame}
				handleLogout={this.props.handleLogout}
			/>
		);
	}
}

FacilitatorController.propTypes = {
	gameId: PropTypes.string.isRequired,
	handleLogout: PropTypes.func.isRequired
};

export default FacilitatorController;
